<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-md-12"> <!-- Ajusta el tamaño de la columna según tus necesidades -->
                <div class="card border-0" style="margin-top: 30px; margin-bottom: 20px;">
                    <div class="card-body">
                        <h1 class="text text-center" style="color:#333333; margin-bottom: 50px;">
                            Acérca de Nosotros
                        </h1>
                        <div v-for="row in terminos" :key="row.title">
                            <h4 style="margin-top: 10px; margin-bottom: 10px;">
                                {{ row.title }}
                            </h4>
                            <p style="color:#737373;font-size:18px;" v-html="row.content"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mb-5">
                <div class="col-md-12">
                    <h3 style="color:#737373;font-size:18px; padding-bottom: 10px">Algunas de las marcas que manejamos:</h3>
                    <div class="grid-container">
                        <div class="grid-item" v-for="(row, i) in brands" :key="i">
                            <div class="img-container">
                                <a :href="row.href">
                                    <img :src="row.img" :alt="row.name" v-if="row.img" class="brand-img" />
                                    <div class="brand-text" v-else>
                                        <h4 class="mt-3">{{ row.name }}</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            terminos: [
                {
                    title: '',
                    content:
                        'PLCSupplies es un proveedor líder de automatización y controles industriales, herramientas y suministros. Suministramos miles de productos, tanto excedentes como directos del fabricante. <br /><br />'
                },
                {
                    title: '',
                    content:
                        'El Programa de Recuperación de Activos Industriales de MRO es significativamente mejor que los métodos tradicionales de gestión de activos excedentes e inutilizados. Nuestro programa ofrece a los clientes un mayor retorno de inversión a través de un marketing eficiente y agresivo. Al conectar los activos excedentes de nuestros socios con los clientes, somos capaces de proporcionar a ambas partes los muchos beneficios de nuestro sistema, incluyendo precios sustancialmente más bajos que el precio minorista sugerido por el fabricante y el retorno de inversión más alto posible. <br /><br />'
                },
                {
                    title: '',
                    content:
                        'PLCSupplies ofrece garantías en todos los productos comprados en nuestras instalaciones. Cubrimos todos los principales fabricantes y una amplia gama de productos: <br /><br />'
                },
                {
                    title: '',
                    content: `
                        <ul>
                            <li>AC Inverters</li>
                            <li>SCR Controllers</li>
                            <li>AC,DC, and Stepper Drives</li>
                            <li>Electronic Brakes</li>
                            <li>Programmable Logic Controllers (PLC)</li>
                            <li>HMI & MMI displays, monitors, and CRTs</li>
                            <li>Light curtains</li>
                            <li>LED Marquees</li>
                            <li>Temperature Controllers</li>
                            <li>Programmable Limit Switches</li>
                            <li>Component Level Repair on PC Boards</li>
                            <li>Teach Pendants</li>
                            <li>Laser Devices, Barcode Scanners</li>
                            <li>Welders and Weld Controls</li>
                            <li>Pneumatic Components</li>
                            <li>CNCs, robots, and more</li>
                        </ul><br /><br />
                    `
                },
                {
                    title: 'Misión',
                    content: `
                    Nuestra misión es ofrecer soluciones integrales a las necesidades de nuestros clientes en la industria,
                    a través de una amplia gama de productos y servicios de alta calidad, así como de un servicio al cliente excepcional.
                    Nos comprometemos a trabajar de manera responsable y sostenible, apoyando el desarrollo de nuestros clientes y el crecimiento de sus negocios.<br /><br />`
                },
                {
                    title: 'Visión',
                    content: `
                    Nuestra visión es ser líderes en la industria, reconocidos por nuestra excelencia en el servicio al cliente y la calidad
                    de nuestros productos y servicios. Nos enfocamos en innovar constantemente y ofrecer soluciones personalizadas para cada
                    uno de nuestros clientes, a fin de satisfacer sus necesidades cambiantes y ser un socio estratégico en el crecimiento de sus negocios. <br /><br />
                    `
                },
                {
                    title: 'Valores',
                    content: `
                    En PLCSupplies, nuestros valores son la base de todo lo que hacemos.
                    Nos enorgullece trabajar con integridad, compromiso, trabajo en equipo, responsabilidad social y ambiental,
                    y excelencia en el servicio al cliente. Estos valores nos permiten ofrecer una experiencia de servicio excepcional a
                    nuestros clientes y asegurar un crecimiento sostenible para nuestra empresa y nuestros clientes. <br />
                    <div style="padding-bottom: 2%;"></div>
                    Nuestro equipo de expertos cuenta con una amplia experiencia en la industria, lo que nos permite ofrecer asesoramiento especializado
                    y personalizado para cada uno de nuestros clientes. Nos enfocamos en entender sus necesidades y objetivos, para poder ofrecer
                    productos y servicios que se adapten a sus requerimientos específicos.<br />
                    <div style="padding-bottom: 2%;"></div>
                    Nos enorgullece contar con una amplia gama de productos de alta calidad, así como una red de proveedores confiables y certificados.
                    Esto nos permite ofrecer productos y servicios de primera calidad a precios competitivos en el mercado.
                    En PLCSupplies estamos comprometidos con la satisfacción del cliente, la innovación y la excelencia en el servicio.
                    Nos esforzamos por ser un socio confiable y de confianza para nuestros clientes, y trabajamos constantemente para mejorar y ampliar
                    nuestros servicios para satisfacer sus necesidades cambiantes. <br />
                    <div style="padding-bottom: 2%;"></div>
                    Si estás buscando un socio de confianza para tus necesidades en la industria, no dudes en contactarnos.
                    En <strong>PLCSupplies</strong>, estamos aquí para ayudarte a alcanzar tus objetivos y crecer tu negocio. <br /><br /><br />

                    `
                },
                {
                    title: 'Contacto',
                    content: `
                    Si tienes alguna pregunta o comentario, por favor ponte en contacto con nosotros a través de los siguientes medios:
                    <div style="padding-bottom: 2%;"></div>
                        <ul>
                            <li>Telefono: +52 (656) 777 7882</li>
                            <li>Correo electrónico: contacto@plcsupplies.com</li>
                            <li>Dirección: Pedro Meneses Hoyos 6360 B3, Cd Juarez CH, MX</li>
                        </ul>
                    `
                }
            ],
            brands: [
                {
                    name: '3M',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/Qi6tIyGX0z2fND0ZV7zA8CZmTzpUfr4VW2SdFw6z.svg'
                },
                {
                    name: 'ABB',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/n6EMrVGL5kst3CDDv80PhHSBj9Q2dPnEG5tbrQpa.svg'
                },
                {
                    name: 'Allen Bradley',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/pltETgEvDTv5zJkKPirxFNkN8NyEzv6u5PLrCSy7.svg'
                },
                {
                    name: 'Beckhoff',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/y5bj010Pcl2vYrCffdTYuhgiIjnOUAhjZAMflkai.svg'
                },
                {
                    name: 'Control Techniques',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/5sVwuYfapBjoevaZeF92ede68MvEB4atSiDqvKyi.svg'
                },
                {
                    name: 'Danfoss',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/wy1yWwMOl7fxHMoqEi5Hf3FI1qYOSt7EMaSn0FoI.svg'
                },
                {
                    name: 'Eaton',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/I3eWpyvFFa5x0tElIT4GNKzzGo0F3J93VzxGw2r9.svg'
                },
                {
                    name: 'Endress & Hauser',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/LsaNtZeJo0c9CaTGoiQRBZa2o2nLY1L2aVBZLYZI.svg'
                },
                {
                    name: 'Festo',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/xyZtuNVuGVo4CrBFbB6DxRnUzgylAKk8ZIm02ris.svg'
                },
                {
                    name: 'Invertek',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/5L53yQTeI0LQlCnVfvCVduIIHuSG00Y7gVEPs4qC.svg'
                },
                {
                    name: 'Mean Well',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/yUJeg5837oZQkwyTC3VzDj9RchcQZ4OD22kC7yd5.svg'
                },
                {
                    name: 'Mitsubishi',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/gjqOizLepwKCF3zs0jDG3IvCKzOOV4Xy6JXB5ve8.svg'
                },
                {
                    name: 'Omron',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/lZVWovIIsRheY4SaKKbcNn6ptnXaXvpGr8RqIwqg.svg'
                },
                {
                    name: 'Phoenix Contact',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/vvvrCOfhbuDLmsclBxskN8cBMmmEDWMZeyK7I6jc.svg'
                },
                {
                    name: 'Schneider Electric',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/99Vzbc75Ysx7ABypH5fSClKFEmlfbqxJ0SVYToJD.svg'
                },
                {
                    name: 'Sew',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/hqyW3QylR3mf9hi7bQokJDg4C5qS9NaA5y3Roq4l.svg'
                },
                {
                    name: 'Sick',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/tGko7RLOZpCSnO6Z4mBIvvERdNQtjv1Q6kH1XE0h.svg'
                },
                {
                    name: 'Siemens',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/1qM7YVQ3HNsHdJJv2c6iv2PrQ05LAD0wxfpC4cUy.svg'
                },
                {
                    name: 'Yaskawa',
                    href: '',
                    description: '',
                    img: 'https://plc-s3.s3.us-east-2.amazonaws.com/media/HNhKrYvTHAIq39BenJ01epGJA5cErP0zxFEQ5Cte.svg'
                },
                {
                    name: '+100 Brands',
                    href: '/brands',
                    description: '',
                    img: ''
                },
            ],
        }
    }
}
</script>

<style scoped>
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
}

.cat-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
}

.grid-item {
    border: 1px solid #ddd;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    /* Ajusta la altura deseada para las imágenes */
}

.brand-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
}

.brand-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    /* Ajusta la altura deseada para el texto */
    border: 0px solid #ddd;
    padding: 10px;
    text-align: center;
}
</style>

