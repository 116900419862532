<template>
    <div>
     <!-- <hr class="mt-3 mb-3" /> -->
     <div class="mt-3 mb-3">
      <!-- <h5 class="mb-3" style="color: #6e6e6e">Pregunta por Disponibilidad:</h5> -->
      <button
       v-if="!is_disabled"
       class="btn btn-lg btn-primary"
       @click="openModal">
       Solicitar información
      </button>
     </div>
     <div class="modal" id="myModal">
      <div class="modal-dialog">
       <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
         <h4 class="modal-title">Solicitar información</h4>
        </div>
   
        <!-- Modal Body -->
        <div class="modal-body">
         <div class="container">
          <div class="custom-col-md-12">
           <div class="form-group mt-3">
            <input
             type="text"
             placeholder="Nombre"
             :class="{ 'form-control': true, 'error': nameError }"
             v-model="name"
             @input="isValidName" />
            <p v-if="nameError" class="error-message">
             Por favor, ingresa un nombre válido.
            </p>
           </div>
           <div class="form-group">
            <input
             type="email"
             class="form-control"
             placeholder="Correo Electronico"
             v-model="email"
             :class="{ 'form-control': true, 'error': emailFormatError }"
             pattern="[^\s@]+@[^\s@]+\.[^\s@]+" />
   
            <p v-if="emailError" class="error-message">
             Por favor, ingresa un correo electrónico.
            </p>
            <p v-if="emailFormatError" class="error-message">
             Por favor, ingresa un formato de correo
             electrónico válido.
            </p>
           </div>
   
           <!-- <div class="form-group">
                                       <input type="text" class="form-control"
                                           placeholder="Telefono con Lada Internacional +52XX.." v-model="phone"
                                           @input="setMask" @focus="setMask" @blur="setMask" />
                                   </div> -->
           <div class="form-group">
            <input
             type="text"
             class="form-control"
             placeholder="Telefono con Lada Internacional +52XX.."
             v-model="phone"
             @input="setMask"
             @focus="setMask"
             @blur="setMask"
             :class="{ 'is-invalid': phoneFormatError }" />
            <p v-if="phoneFormatError" class="error-message">
             {{ phoneErrorMessage }}
            </p>
           </div>
           <!-- <div class="form-group">
               <select class="form-select" aria-label="Purchase Option" v-model="purchase_option">
               <option value="1">Quiero ser Distribuidor.</option>
               <option value="2">Busco comprar al Mayoreo.</option>
               <option value="3">Una sola compra.</option>
               </select>
           </div> -->
           <div class="form-group">
            <p v-html="getProductInformation()" rows="5"></p>
            <!-- <textarea class="form-control" rows="5">{{ getProductInformation() }}</textarea> -->
           </div>
          </div>
         </div>
        </div>
   
        <!-- Modal Footer -->
        <div class="modal-footer">
         <!-- <button type="button" class="btn btn-primary" @click.prevent="checkWhastappPerm" :disabled="notAllowedToSendAnotherAfterTimeout">
                               Reenviar Codigo
                           </button> -->
         <button
          type="button"
          class="btn btn-primary"
          @click.prevent="sendRfq">
          Enviar RFQ
         </button>
         <button
          type="button"
          class="btn btn-danger"
          style="color: white"
          data-dismiss="modal"
          @click="closeModal"
          >
          Cerrar
         </button>
        </div>
       </div>
      </div>
     </div>
     <div class="modal" id="modalSuccess">
      <div class="modal-dialog">
       <div class="modal-content">
        <!-- Modal Header -->
        <!-- Modal Body -->
        <div class="modal-body">
         <div class="container">
          <center>
           <h4 class="mt-4">
            Tu Mensaje a sido Enviado. <br />
            En un momento nos pondremos en contacto con usted
           </h4>
          </center>
          <br />
          <center>
           <img
            class="text text-center"
            src="https://picsvg.com/svg/1MpBqe.jpg" />
          </center>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </template>
   
   <script>
   export default {
       props: {
           item: {
               type: String,
               required: true
           },
           is_disabled: {
               type: Boolean,
               required: true
           }
       },
       data() {
           return {
               name: "",
               email: "",
               phone: "+",
               captcha: null,
               info: {
                   product: [],
                   stock: [],
               },
               nameError: false,
               emailError: false,
   
               emailFormatError: false,
               nameFormatError: false,
               isValidCaptcha: false,
               whatsappPermission: null,
               captchaByWhastEmail: 0,
               captchaGenerated: null,
               captchaGeneratedWhatsapp: null,
               notAllowedToSendAnotherAfterTimeout: true,
               purchase_option: 1
           };
       },
       methods: {
           getProductInformation(val) {
               return `
                   Me podrían mandar cotizacion sobre este producto y la disponibilidad que tiene:<br />
                   SKU: ${this.item.sku}<br />
               `;
           },
           setMask() {
               let matrix = "+###############";
   
               this.maskList.forEach((item) => {
                   let code = item.code.replace(/[\s#]/g, ""),
                       phone = this.phone.replace(/[\s#-)(]/g, "");
   
                   if (phone.includes(code)) {
                       matrix = item.code;
                   }
               });
   
               let i = 0,
                   val = this.phone.replace(/\D/g, "");
   
               this.phone = matrix.replace(/(?!\+)./g, function (a) {
                   return /[#\d]/.test(a) && i < val.length
                       ? val.charAt(i++)
                       : i >= val.length
                           ? ""
                           : a;
               });
           },
           extractCode(warehouseCode) {
               // Extraer los primeros 4 caracteres (por ejemplo, "CDJZ") del almacen_code
               const parts = warehouseCode.split("-");
               if (parts[0] == "CDJZ") {
                   return "Cd Juarez Chihuahua";
               } else if (parts[0] == "INT") {
                   return "Internacional";
               }
           },
           openModal(row) {
               // Abre el modal y establece los datos del almacén seleccionado
               this.info = {
                   product: this.item,
                   stock: row,
               };
               this.name = '';
               this.email = '';
               this.phone = '';
               this.captcha = '';
               this.isValidCaptcha = false;
               this.whatsappPermission = 0;
               this.notAllowedToSendAnotherAfterTimeout = true;
               $("#myModal").modal("show");
           },
           validateCaptcha() {
               if (this.captcha == this.captchaGeneratedWhatsapp) {
                   this.isValidCaptcha = true;
               } else {
                   this.isValidCaptcha = false;
               }
           },
           generateCaptcha() {
               const characters =
                   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
               let captcha = "";
               for (let i = 0; i < 5; i++) {
                   const randomIndex = Math.floor(
                       Math.random() * characters.length
                   );
                   captcha += characters.charAt(randomIndex);
               }
               this.captchaByWhastEmail = 2;
               return captcha;
           },
           generateCaptchaNumbers() {
               const characters = "0123456789";
               let captcha = "";
               for (let i = 0; i < 4; i++) {
                   const randomIndex = Math.floor(
                       Math.random() * characters.length
                   );
                   captcha += characters.charAt(randomIndex);
               }
               this.captchaByWhastEmail = 1;
               return captcha;
           },
           checkWhastappPerm() {
               let phoneNumber = this.phone;
   
               // Validar si el número de teléfono está presente
               if (!phoneNumber) {
                   this.phoneFormatError = true;
                   this.whatsappPermission = 1;
                   this.phoneErrorMessage = 'Por favor, ingresa tu número de teléfono.';
                   return;
               } else {
                   this.phoneFormatError = false;
                   this.phoneErrorMessage = '';
               }
   
               if (
                   this.whatsappPermission == 1 &&
                   this.notAllowedToSendAnotherAfterTimeout
               ) {
   
                   if (phoneNumber.startsWith("+52")) {
                       let parts = phoneNumber.split("+52");
                       let afterCountryCode = parts[1].replace(/\s/g, "");
                       phoneNumber = "52 1 " + afterCountryCode;
                   }
   
                   let phoneNumberWithoutSpaces = phoneNumber.replace(/\s/g, "");
   
                   this.captchaGeneratedWhatsapp = this.generateCaptchaNumbers();
                   const message = 'Codigo de Verificacion: ' + this.captchaGeneratedWhatsapp
                   this.sendWhatsapp(phoneNumberWithoutSpaces, message);
   
                   setTimeout(() => {
                       this.captcha = "";
                       this.whatsappPermission = 0;
                       this.notAllowedToSendAnotherAfterTimeout = true;
                   }, 300000);
   
               } else if (this.whatsappPermission == 3 && this.notAllowedToSendAnotherAfterTimeout) {
   
                   let phoneNumberWithoutSpaces = phoneNumber.replace(/\s/g, "");
   
                   this.captchaGeneratedWhatsapp = this.generateCaptchaNumbers()
   
                   const data =
                   {
                       phone: phoneNumberWithoutSpaces,
                       code: this.captchaGeneratedWhatsapp
                   };
   
                   axios.post('/admin/request/send/sms', data).then(res => {
                       // console.log(res)
                       this.notAllowedToSendAnotherAfterTimeout = false;
                       setTimeout(() => {
                           this.captcha = "";
                           this.whatsappPermission = 0;
                           this.notAllowedToSendAnotherAfterTimeout = true;
                       }, 300000);
                   })
               }
           },
           async sendWhatsapp(phone, message) {
               const formdata = new FormData();
   
               formdata.append("appkey", "bfd508b8-c28a-4c14-8e3f-7a7c091fe47c");
               formdata.append(
                   "authkey",
                   "4h5aYpKFPEpTsfUYpuA13BsKNr6Q7E3k6XItDnz3wLT6wbBhXi"
               );
   
               const formDataCopy = new FormData();
   
               formDataCopy.append("appkey", formdata.get("appkey"));
               formDataCopy.append("authkey", formdata.get("authkey"));
               formDataCopy.append("to", phone);
               formDataCopy.append(
                   "message", message
               );
               axios
                   .post(
                       "https://sms.plcsupplies.com/api/create-message",
                       formDataCopy
                   )
                   .then((response) => {
                       this.notAllowedToSendAnotherAfterTimeout = false;
                       if (response.data.message_status != "Success") {
                           console.log("El numero de Telefono no es valido");
                           this.whatsappPermission = 0;
                       }
                   })
                   .catch((error) => {
                       this.whatsappPermission = 0;
                       console.log("error", error);
                   });
           },
           async sendWhatsappTemplate(phone) {
               const formdata = new FormData();
   
               formdata.append("appkey", "bfd508b8-c28a-4c14-8e3f-7a7c091fe47c");
               formdata.append(
                   "authkey",
                   "4h5aYpKFPEpTsfUYpuA13BsKNr6Q7E3k6XItDnz3wLT6wbBhXi"
               );
   
               const formDataCopy = new FormData();
   
               formDataCopy.append("appkey", formdata.get("appkey"));
               formDataCopy.append("authkey", formdata.get("authkey"));
               formDataCopy.append("to", phone);
               formDataCopy.append("template_id", "88dfa118-e206-4eb9-b786-dbff145c1e26");
               formDataCopy.append("variables[{name}]", this.name);
               formDataCopy.append("variables[{email}]", this.email);
               formDataCopy.append("variables[{phone}]", this.phone);
               formDataCopy.append("variables[{sku}]", this.info.product.sku);
               formDataCopy.append("variables[{warehouse}]", this.info.stock.code || "");
               formDataCopy.append("variables[{price}]", this.info.stock.price || "");
   
               axios
                   .post(
                       "https://sms.plcsupplies.com/api/create-message",
                       formDataCopy
                   )
                   .then((response) => {
                       this.notAllowedToSendAnotherAfterTimeout = false;
                       if (response.data.message_status != "Success") {
                           console.log("El numero de Telefono no es valido");
                           this.whatsappPermission = 0;
                       }
                   })
                   .catch((error) => {
                       this.whatsappPermission = 0;
                       console.log("error", error);
                   });
           },
           async sendEmail() {
               const data = {
                   'name': this.name,
                   'email': this.email,
                   'phone': this.phone,
                   'sku': this.item.sku,
                   'purchase_option': this.purchase_option
               }
               axios.post('/api/send-email', data).then(res => {
                   $("#myModal").modal("hide");
                   // $("#modalSuccess").modal('show');
               })
           },
           sendRfq() {
               //Admins
               this.validateName();
               this.validateEmail();
   
               if (this.nameError) {
                   this.nameFormatError = true;
               }
   
               if (this.emailError) {
                   this.emailFormatError = true;
               }
   
               if (this.nameError || this.emailError) {
                   return;
               }
   
               // this.sendWhatsappTemplate("5216568268726");
               // this.sendWhatsappTemplate("5216565629221");
               this.sendEmail();
   
               setTimeout(() => {
                   $("#modalSuccess").modal('hide');
               }, 3000);
           },
           validateEmail() {
               const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
               this.emailError = !emailRegex.test(this.email);
           },
           validateName() {
               this.nameError = this.name.trim() === '';
           },
           closeModal() {
               $("#myModal").modal("hide");
           }
       },
       computed: {
           maskList() {
               return [
                   { code: "+247 ####" },
                   { code: "+290 ####" },
                   { code: "+290 ####" },
                   { code: "+683 ####" },
                   { code: "+690 ####" },
                   { code: "+500 #####" },
                   { code: "+676 #####" },
                   { code: "+677 #####" },
                   { code: "+678 #####" },
                   { code: "+688 2####" },
                   { code: "+49 ### ###" },
                   { code: "+682 ## ###" },
                   { code: "+686 ## ###" },
                   { code: "+688 90####" },
                   { code: "+95 ### ###" },
                   { code: "+298 ### ###" },
                   { code: "+376 ### ###" },
                   { code: "+387 ## ####" },
                   { code: "+508 ## ####" },
                   { code: "+597 ### ###" },
                   { code: "+672 1## ###" },
                   { code: "+672 3## ###" },
                   { code: "+681 ## ####" },
                   { code: "+685 ## ####" },
                   { code: "+687 ## ####" },
                   { code: "+850 ### ###" },
                   { code: "+230 ### ####" },
                   { code: "+239 ## #####" },
                   { code: "+245 # ######" },
                   { code: "+246 ### ####" },
                   { code: "+263 # ######" },
                   { code: "+269 ## #####" },
                   { code: "+297 ### ####" },
                   { code: "+299 ## ## ##" },
                   { code: "+354 ### ####" },
                   { code: "+372 ### ####" },
                   { code: "+387 ## #####" },
                   { code: "+49 ### ## ##" },
                   { code: "+501 ### ####" },
                   { code: "+507 ### ####" },
                   { code: "+592 ### ####" },
                   { code: "+597 ### ####" },
                   { code: "+599 ### ####" },
                   { code: "+599 ### ####" },
                   { code: "+599 ### ####" },
                   { code: "+60 # ### ###" },
                   { code: "+62 ## ### ##" },
                   { code: "+65 #### ####" },
                   { code: "+670 ### ####" },
                   { code: "+673 ### ####" },
                   { code: "+674 ### ####" },
                   { code: "+677 ### ####" },
                   { code: "+678 ## #####" },
                   { code: "+679 ## #####" },
                   { code: "+680 ### ####" },
                   { code: "+689 ## ## ##" },
                   { code: "+691 ### ####" },
                   { code: "+692 ### ####" },
                   { code: "+95 # ### ###" },
                   { code: "+960 ### ####" },
                   { code: "+220 ### ## ##" },
                   { code: "+232 ## ######" },
                   { code: "+234 ## ### ##" },
                   { code: "+237 #### ####" },
                   { code: "+238 ### ## ##" },
                   { code: "+248 # ### ###" },
                   { code: "+252 # ### ###" },
                   { code: "+252 # ### ###" },
                   { code: "+265 1 ### ###" },
                   { code: "+291 # ### ###" },
                   { code: "+350 ### #####" },
                   { code: "+356 #### ####" },
                   { code: "+372 #### ####" },
                   { code: "+373 #### ####" },
                   { code: "+47 ### ## ###" },
                   { code: "+49 ### ## ###" },
                   { code: "+504 #### ####" },
                   { code: "+505 #### ####" },
                   { code: "+506 #### ####" },
                   { code: "+52 ## ## ####" },
                   { code: "+53 # ### ####" },
                   { code: "+599 9### ####" },
                   { code: "+60 ## ### ###" },
                   { code: "+62 ## ### ###" },
                   { code: "+64 ## ### ###" },
                   { code: "+66 ## ### ###" },
                   { code: "+670 77# #####" },
                   { code: "+670 78# #####" },
                   { code: "+850 #### ####" },
                   { code: "+852 #### ####" },
                   { code: "+853 #### ####" },
                   { code: "+886 #### ####" },
                   { code: "+95 ## ### ###" },
                   { code: "+961 # ### ###" },
                   { code: "+965 #### ####" },
                   { code: "+967 # ### ###" },
                   { code: "+973 #### ####" },
                   { code: "+974 #### ####" },
                   { code: "+975 # ### ###" },
                   { code: "+1 ### ### ####" },
                   { code: "+1 242 ### ####" },
                   { code: "+1 246 ### ####" },
                   { code: "+1 264 ### ####" },
                   { code: "+1 268 ### ####" },
                   { code: "+1 284 ### ####" },
                   { code: "+1 340 ### ####" },
                   { code: "+1 345 ### ####" },
                   { code: "+1 441 ### ####" },
                   { code: "+1 473 ### ####" },
                   { code: "+1 649 ### ####" },
                   { code: "+1 664 ### ####" },
                   { code: "+1 670 ### ####" },
                   { code: "+1 671 ### ####" },
                   { code: "+1 684 ### ####" },
                   { code: "+1 721 ### ####" },
                   { code: "+1 758 ### ####" },
                   { code: "+1 767 ### ####" },
                   { code: "+1 784 ### ####" },
                   { code: "+1 809 ### ####" },
                   { code: "+1 829 ### ####" },
                   { code: "+1 849 ### ####" },
                   { code: "+1 868 ### ####" },
                   { code: "+1 869 ### ####" },
                   { code: "+1 876 ### ####" },
                   { code: "+216 ## ### ###" },
                   { code: "+218 ## ### ###" },
                   { code: "+222 ## ## ####" },
                   { code: "+223 ## ## ####" },
                   { code: "+224 ## ### ###" },
                   { code: "+225 ## ### ###" },
                   { code: "+226 ## ## ####" },
                   { code: "+227 ## ## ####" },
                   { code: "+228 ## ### ###" },
                   { code: "+229 ## ## ####" },
                   { code: "+231 ## ### ###" },
                   { code: "+234 ## ### ###" },
                   { code: "+236 ## ## ####" },
                   { code: "+241 # ## ## ##" },
                   { code: "+252 ## ### ###" },
                   { code: "+254 ### ######" },
                   { code: "+257 ## ## ####" },
                   { code: "+258 ## ### ###" },
                   { code: "+262 ##### ####" },
                   { code: "+262 ##### ####" },
                   { code: "+266 # ### ####" },
                   { code: "+267 ## ### ###" },
                   { code: "+268 ## ## ####" },
                   { code: "+27 ## ### ####" },
                   { code: "+31 ## ### ####" },
                   { code: "+32 ### ### ###" },
                   { code: "+33 ### ### ###" },
                   { code: "+34 ### ### ###" },
                   { code: "+357 ## ### ###" },
                   { code: "+36 ### ### ###" },
                   { code: "+370 ### ## ###" },
                   { code: "+371 ## ### ###" },
                   { code: "+374 ## ### ###" },
                   { code: "+377 ## ### ###" },
                   { code: "+382 ## ### ###" },
                   { code: "+385 ## ### ###" },
                   { code: "+386 ## ### ###" },
                   { code: "+389 ## ### ###" },
                   { code: "+39 6 698 #####" },
                   { code: "+40 ## ### ####" },
                   { code: "+41 ## ### ####" },
                   { code: "+45 ## ## ## ##" },
                   { code: "+46 ## ### ####" },
                   { code: "+48 ### ### ###" },
                   { code: "+49 ### ## ####" },
                   { code: "+502 # ### ####" },
                   { code: "+503 ## ## ####" },
                   { code: "+509 ## ## ####" },
                   { code: "+51 ### ### ###" },
                   { code: "+56 # #### ####" },
                   { code: "+591 # ### ####" },
                   { code: "+593 # ### ####" },
                   { code: "+594 ##### ####" },
                   { code: "+60 ## ### ####" },
                   { code: "+60 ### ### ###" },
                   { code: "+61 # #### ####" },
                   { code: "+62 ## ### ####" },
                   { code: "+62 8## ### ###" },
                   { code: "+64 ### ### ###" },
                   { code: "+66 ## ### ####" },
                   { code: "+675 ### ## ###" },
                   { code: "+81 ### ### ###" },
                   { code: "+82 ## ### ####" },
                   { code: "+84 ## #### ###" },
                   { code: "+850 ## ### ###" },
                   { code: "+855 ## ### ###" },
                   { code: "+856 ## ### ###" },
                   { code: "+880 ## ### ###" },
                   { code: "+93 ## ### ####" },
                   { code: "+94 ## ### ####" },
                   { code: "+961 ## ### ###" },
                   { code: "+966 # ### ####" },
                   { code: "+967 ## ### ###" },
                   { code: "+968 ## ### ###" },
                   { code: "+971 # ### ####" },
                   { code: "+972 # ### ####" },
                   { code: "+975 17 ### ###" },
                   { code: "+976 ## ## ####" },
                   { code: "+977 ## ### ###" },
                   { code: "+993 # ### ####" },
                   { code: "+20 ### ### ####" },
                   { code: "+211 ## ### ####" },
                   { code: "+212 ## #### ###" },
                   { code: "+213 ## ### ####" },
                   { code: "+218 21 ### ####" },
                   { code: "+221 ## ### ####" },
                   { code: "+233 ### ### ###" },
                   { code: "+235 ## ## ## ##" },
                   { code: "+240 ## ### ####" },
                   { code: "+242 ## ### ####" },
                   { code: "+243 ### ### ###" },
                   { code: "+244 ### ### ###" },
                   { code: "+249 ## ### ####" },
                   { code: "+250 ### ### ###" },
                   { code: "+251 ## ### ####" },
                   { code: "+253 ## ## ## ##" },
                   { code: "+255 ## ### ####" },
                   { code: "+256 ### ### ###" },
                   { code: "+260 ## ### ####" },
                   { code: "+261 ## ## #####" },
                   { code: "+264 ## ### ####" },
                   { code: "+265 # #### ####" },
                   { code: "+30 ### ### ####" },
                   { code: "+351 ## ### ####" },
                   { code: "+352 ### ### ###" },
                   { code: "+353 ### ### ###" },
                   { code: "+355 ### ### ###" },
                   { code: "+359 ### ### ###" },
                   { code: "+377 ### ### ###" },
                   { code: "+378 #### ######" },
                   { code: "+381 ## ### ####" },
                   { code: "+39 ### #### ###" },
                   { code: "+420 ### ### ###" },
                   { code: "+421 ### ### ###" },
                   { code: "+43 ### ### ####" },
                   { code: "+44 ## #### ####" },
                   { code: "+49 ### ### ####" },
                   { code: "+52 ### ### ####" },
                   { code: "+54 ### ### ####" },
                   { code: "+55 ## #### ####" },
                   { code: "+55 ## 7### ####" },
                   { code: "+57 ### ### ####" },
                   { code: "+58 ### ### ####" },
                   { code: "+590 ### ### ###" },
                   { code: "+593 ## ### ####" },
                   { code: "+595 ### ### ###" },
                   { code: "+598 # ### ## ##" },
                   { code: "+62 8## ### ####" },
                   { code: "+63 ### ### ####" },
                   { code: "+64 ### ### ####" },
                   { code: "+7 ### ### ## ##" },
                   { code: "+7 6## ### ## ##" },
                   { code: "+7 7## ### ## ##" },
                   { code: "+81 ## #### ####" },
                   { code: "+84 ### #### ###" },
                   { code: "+86 ### #### ###" },
                   { code: "+886 # #### ####" },
                   { code: "+90 ### ### ####" },
                   { code: "+91 #### ### ###" },
                   { code: "+92 ### ### ####" },
                   { code: "+962 # #### ####" },
                   { code: "+963 ## #### ###" },
                   { code: "+966 5 #### ####" },
                   { code: "+967 ### ### ###" },
                   { code: "+970 ## ### ####" },
                   { code: "+971 5# ### ####" },
                   { code: "+972 5# ### ####" },
                   { code: "+98 ### ### ####" },
                   { code: "+992 ## ### ####" },
                   { code: "+995 ### ### ###" },
                   { code: "+996 ### ### ###" },
                   { code: "+998 ## ### ####" },
                   { code: "+234 ### ### ####" },
                   { code: "+234 ### ### ####" },
                   { code: "+375 ## ### ## ##" },
                   { code: "+380 ## ### ## ##" },
                   { code: "+423 ### ### ####" },
                   { code: "+49 #### ### ####" },
                   { code: "+55 ## 9#### ####" },
                   { code: "+596 ### ## ## ##" },
                   { code: "+850 ### #### ###" },
                   { code: "+850 191 ### ####" },
                   { code: "+856 20## ### ###" },
                   { code: "+86 ### #### ####" },
                   { code: "+964 ### ### ####" },
                   { code: "+994 ## ### ## ##" },
                   { code: "+358 ### ### ## ##" },
                   { code: "+62 8## ### ## ###" },
                   { code: "+86 ## ##### #####" },
                   { code: "+850 #### #############" },
               ];
           },
           hasStockInAnyWarehouse() {
               // Comprueba si hay stock en algún almacén
               return this.stock.some(
                   (warehouse) => warehouse.quantity > 0
               );
           },
           itemValue: {
               get() {
                   return this.item; // Devuelve el valor actual de item
               },
               set(newValue) {
                   // Puedes agregar lógica aquí si necesitas manejar cambios en item
               }
           }
       },
       mounted() {
           this.captchaGenerated = this.generateCaptcha();
       },
       filters: {
           currency(value) {
               // Formatea el valor numérico como una cadena de moneda (puedes personalizarlo según tus necesidades)
               const formatter = new Intl.NumberFormat('en-US', {
                   style: 'currency',
                   currency: 'USD', // Cambia según tu moneda
                   minimumFractionDigits: 2,
               });
               return formatter.format(value) + " USD";
           },
       },
       watch: {
           itemValue(newVal) {
               // Maneja los cambios en la propiedad itemValue aquí
               console.log('Nuevo valor de item:', newVal);
               // Puedes hacer algo con el nuevo valor
           }
       }
   };
   </script>
   
   <style scoped>
   .custom-col-md-12 {
       width: 100%;
   }
   
   .table .thead-dark th {
       color: #fff;
       background-color: #004468;
       border-color: #004468;
   }
   
   .badge {
       font-size: 14px;
       font-weight: 500;
       min-width: 120px;
       display: inline-block;
       padding: 10px 15px;
       text-align: center;
       border-radius: 5px;
   }
   
   .badge-success {
       color: #000000;
       background: #54d40f;
   }
   
   .badge-danger {
       color: #ffffff;
       background: #b80101;
   }
   
   .table tbody tr:first-child td {
       padding-top: 10px;
   }
   
   .table td {
       padding: 10px 10px;
       border-color: #ebebeb;
   }
   
   .error {
       border: 1px solid red;
   }
   
   .error-message {
       color: red;
       margin-top: 5px;
   }
   </style>
   